<template>
    <div class="form-group row">
        <div class="col-sm-8 offset-sm-2 accordion">
            <div class="card">
                <div class="card-header">
                    <h5 class="mb-0">
                        <button type="button" class="btn btn-link" data-toggle="collapse" data-target="#collapseTwo"
                                aria-expanded="true" aria-controls="collapseTwo">
                            {{$trans('article.edit.headers.variants')}}
                            <i class="fa fa-chevron-down"></i>
                        </button>
                    </h5>
                </div>
                <div id="collapseTwo" class="collapse show" data-parent=".accordion">
                    <div class="card-body">
                        <table class="table table-sm">
                            <thead class="thead-dark">
                            <tr>
                                <th scope="col">{{$trans('article.individual.edit.headers.quantity')}}</th>
                                <th scope="col">{{$trans('article.individual.edit.headers.measureUnit')}}</th>
                                <th scope="col">{{$trans('article.individual.edit.headers.price')}}</th>
                                <th scope="col">
                                    <button class="btn btn-sm btn-outline-success btn-toggle" v-show="!allQuantitiesEnabled"
                                            v-on:click.stop.prevent="toggleAllStatuses()">
                                        {{$trans('article.edit.labels.btnEnableAll')}}
                                    </button>
                                    <button class="btn btn-sm btn-outline-danger btn-toggle" v-show="allQuantitiesEnabled"
                                            v-on:click.stop.prevent="toggleAllStatuses()">
                                        {{$trans('article.edit.labels.btnDisableAll')}}
                                    </button>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-show="!loading">
                                <td><input type="number" v-model="quantity" class="form-control form-control-sm"
                                           name="quantity" ref="quantityInput"/></td>
                                <td><input type="text" v-model="measureUnit" class="form-control form-control-sm"
                                           name="measureUnit" ref="measureUnitInput"/></td>
                                <td><input type="number" v-model="price" class="form-control form-control-sm"
                                           name="price" ref="priceInput"/></td>
                                <td>
                                    <i class="fa fa-plus" v-show="!addLoading && isValidQuantity && inputsAreFilled"
                                       v-on:click="addVariant()"></i>
                                    <i class="fa fa-spinner fa-spin" v-show="addLoading"></i>
                                    <i class="fa fa-ban" v-show="!isValidQuantity"></i>
                                </td>
                            </tr>
                            <tr v-for="(item, index) in variants">
                                <td v-bind:class="[item.status === 0 ? 'table-danger': 'table-success']">{{item.quantity}}</td>
                                <td v-bind:class="stateClass(item.status)">{{item.measure_unit}}</td>
                                <td v-bind:class="stateClass(item.status)">{{item.price}}</td>
                                <td>
                                    <button class="btn btn-sm btn-outline-success btn-toggle" v-show="item.status == 0"
                                            v-on:click.stop.prevent="toggleStatus(index, item.id)">
                                        {{$trans('article.individual.edit.labels.btnEnable')}}
                                    </button>
                                    <button class="btn btn-sm btn-outline-danger btn-toggle" v-show="item.status == 1"
                                            v-on:click.stop.prevent="toggleStatus(index, item.id)">
                                        {{$trans('article.individual.edit.labels.btnDisable')}}
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ["articleId"],
        data() {
            return {
                variants: [],
                loading: false,
                addLoading: false,
                quantity: '',
                measureUnit: '',
                price: '',
                allQuantitiesEnabled: true
            }
        },
        computed: {
            isValidQuantity: function () {
                return this.variants.findIndex(variant => variant.quantity === this.quantity) === -1;
            },
            inputsAreFilled: function () {
                return this.quantity !== '' && this.price !== '' && this.measureUnit !== '';
            }
        },
        mounted() {
            this.loading = true;
            let _that = this;
            axios.get(route('api.individual_article.show', {'id': this.articleId}))
                .then(response => {
                    this.variants = response.data;

                    this.variants.forEach(function (item) {
                        if (item.status == 0) _that.allQuantitiesEnabled = false;
                        return;
                    });

                    this.loading = false;
                })
                .catch(function (error) {
                    console.log(error);
                    this.loading = false;
                });
        },
        methods: {
            reset() {
                this.measureUnit = '';
                this.quantity = '';
                this.price = '';
            },
            toggleStatus(index, id) {
                axios.patch(route('api.article.toggle', {id: id, type: 'single'}))
                    .then(response => {
                        this.variants[index].status = response.data;
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            addVariant() {
                let formData = new FormData();
                formData.append('article_id', this.articleId);
                formData.append('quantity', this.quantity);
                formData.append('measure_unit', this.measureUnit);
                formData.append('price', this.price);
                this.addLoading = true;
                axios.post(
                    route('api.individual_article.store', {id: this.articleId}),
                    formData,
                    {headers: {'Content-Type': 'multipart/form-data'}}
                )
                    .then(response => {
                        this.variants.push(response.data);
                        this.addLoading = false;
                        this.reset();
                    })
                    .catch(error => {
                        console.log(error);
                        this.addLoading = false;
                    });
            },
            stateClass: function (status) {
                return status === 0 ? 'table-danger' : 'table-success';
            },
            toggleAllStatuses: function () {
                let _that = this;
                axios.patch(route('api.article.toggle', {id:this.articleId, type:'all' ,action: Number(!this.allQuantitiesEnabled)}))
                    .then(response => {
                        this.variants = response.data;
                        this.allQuantitiesEnabled = !this.allQuantitiesEnabled;
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    }
</script>
