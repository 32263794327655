<template>
    <div>
        <div class="form-group row" v-show="!stackFull">
            <label class="col-sm-4 col-form-label">{{$trans('template.create.headers.title')}}</label>
            <div class="col-sm-8">
                <span v-show="loading">
                    <i class="fa fa-spinner fa-spin"></i>
                </span>
                <div class="custom-file" v-show="!loading">
                    <input type="file" class="custom-file-input" name="files" ref="filesInput" multiple
                           v-on:change="handleFilesSelected()" accept="application/pdf"/>
                    <label class="custom-file-label" v-on:click="addFiles()">{{$trans('article.edit.placeholders.chooseFile')}}</label>
                </div>
                <div class="col-sm-1" v-if="uploaded">
                    <span class="badge badge-success">{{$trans('shop.messages.cart.uploadSuccess')}}</span>
                </div>
            </div>
        </div>
        <input type="hidden" v-for="(file, key) in files" v-bind:name="'pdf[' + key +']'" v-bind:value="file.id"/>
    </div>
</template>

<script>
    export default {
        props: [],
        data() {
            return {
                errors: [],
                files: [],
                temps: [],
                maxFiles: 1,
                loading: false,
                type: 1,
                pdfPreview: true,
                uploaded: false
            }
        },
        computed: {
            stackFull: function () {
                return this.files.length >= this.maxFiles;
            },
        },
        mounted() {
        },
        methods: {
            addFiles() {
                this.$refs.filesInput.click();
            },
            handleFilesSelected() {
                this.resetErrors();
                let uploadedFiles = this.$refs.filesInput.files;
                for (let i = 0; i < uploadedFiles.length; i++) {
                    if (this.maxFiles <= this.temps.length + this.files.length) {
                        this.errors.push("Too many pdfs selected.");
                        continue;
                    }
                    this.temps.push(uploadedFiles[i]);
                }
                this.submitFiles();
            },
            submitFiles() {
                let formData = new FormData();
                for (let i = 0; i < this.temps.length; i++) {
                    let file = this.temps[i];
                    formData.append('pdfs[' + i + ']', file);
                }
                let title = document.getElementsByClassName('article-title')[0].innerText;
                title = title.split(',').slice(0,1).join('_').replace(/\s+/g, '_').toLowerCase();
                title += '_' + Math.random().toString(36).replace('0.', '')
                formData.append('title', title);
                formData.append('type', this.type);
                formData.append('pdfPreview', this.pdfPreview);
                let _that = this;
                this.$refs.filesInput.value = "";
                this.loading = true;
                axios.post(
                    route('api.templates.store'),
                    formData,
                    {headers: {'Content-Type': 'multipart/form-data'}}
                )
                    .then(function (response) {
                        _that.temps = [];
                        _that.loading = false;
                        _that.uploaded = true;
                        let url = window.location.href;
                        // url += '?template=' + response.data.data.id;
                        let templatesDropDown = document.getElementById('template-id');
                        templatesDropDown.options.add(new Option(response.data.data.name, response.data.data.id, false, true));
                        let addToCartButton = document.getElementsByClassName('btn-add-to-cart')[0];
                        addToCartButton.removeAttribute('disabled');
                    })
                    .catch(function (error) {
                        console.log(error);
                        _that.loading = false;
                        _that.errors[0] = [error.response.data.message];
                        _that.temps = [];
                    });
            },
            resetErrors: function () {
                this.errors = [];
            },
            hasError: function () {
                return this.errors.length;
            },
            firstError: function () {
                return this.errors[0];
            },
        }
    }
</script>
