<template>
    <div>
        <form v-on:submit.prevent>
            <div class="form-group row">
                <label class="col-sm-2 offset-sm-2 col-form-label">{{$trans('article.create.labels.subgroups')}}</label>
                <div class="col-sm-6">
                    <product-autosuggest-input :loadingSubgroups="loadingSubgroups" :options="subgroups" :loadingAttributes="loadingAttributes"></product-autosuggest-input>
                </div>

                <div class="col-sm-2">
                    <i class="fa fa-spinner fa-spin" v-bind:class="{'collapse': !loadingSubgroups }"></i>
                </div>
            </div>
            <article-attribute-builder v-for="(attribute, index) in attributes"
                                       :key="index"
                                       :index="index"
                                       :attribute="attribute"
                                       :trimmedAttribute="trimmedAttributes[index]"
                                        :loadingAttributes="loadingAttributes">
            </article-attribute-builder>
            <span v-if="variants.count">{{$trans('article.create.labels.variants')}} {{ variants.count }}</span>
            <article-result-builder v-show="searchForProduct"
                                    :search-for-product="searchForProduct"
                                    :variant-id="variantId"
                                    :subgroup-id="subgroupSelected"
            >
            </article-result-builder>
        </form>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                errors: {},
                searchForProduct: false,
                attributes: {},
                selectedAttributes: [],

                loadingTypes: false,
                loadingGroups: false,
                loadingSubgroups: false,
                loadingAttributes: false,

                types: [],
                groups: [],
                groupSelected: '',
                subgroups: [],
                subgroupSelected: '',
                trimmedAttributes: {},
                variants: {},
                variantId: ''
            }
        },
        computed: {},
        mounted() {
            this.getSubGroups();
            window.bus.$on('updateAttributeList', this.updateAttributeList);
            window.bus.$on('removeAttributeByIndex', this.removeAttributeByIndex);

            window.bus.$on('subgroupSelect', (subgroup) => {
                this.subgroupSelected = subgroup.id;
                this.subgroupWasSelected();
            })
        },
        methods: {
            getSubGroups: function () {
                let _that = this;
                _that.resetErrors();
                _that.loadingSubgroups = true;
                axios.get(route('api.articles.builder.subgroups'), {})
                    .then(function (response) {
                        _that.subgroups = response.data.data;
                        _that.loadingSubgroups = false;
                    })
                    .catch(function (error) {
                        console.log(error);
                        _that.$set(_that.errors, 'subgroups', [error.response.data.message]);
                        _that.loadingSubgroups = false;
                    });

            },
            subgroupWasSelected: function () {
                if (this.subgroupSelected === '') return;
                this.searchForProduct = false;
                this.attributes = [];
                this.selectedAttributes = [];
                this.getAllAttributes();
            },
            getAllAttributes: function () {
                let _that = this;
                _that.resetErrors();
                _that.loadingSubgroups = true;
                _that.searchForProduct = false;
                axios.post(route('api.articles.builder.attributes'), {'subgroupId': this.subgroupSelected})
                    .then(function (response) {
                        _that.variants = response.data.data.variants;
                        if (_that.variants.count === 1) {
                            _that.searchForProduct = true;
                            _that.variantId = _that.variants.result;
                        } else {
                            _that.attributes = response.data.data.attributes;
                            _that.variants = response.data.data.variants;

                        }
                        _that.loadingSubgroups = false;
                    })
                    .catch(function (error) {
                        console.log(error);
                        _that.$set(_that.errors, 'attributes', [error.response.data.message]);
                        _that.loadingSubgroups = false;
                    });
            },
            getFilteredAttributes: function () {
                let _that = this;
                _that.resetErrors();
                _that.loadingAttributes = true;
                _that.searchForProduct = false;
                axios.post(route('api.articles.builder.attributes'), {'subgroupId': this.subgroupSelected, 'used_attribute_ids': this.selectedAttributes})
                    .then(function (response) {
                        if(response.data.length === 0) {
                        } else {
                            _that.trimmedAttributes = response.data.data.attributes;
                            _that.variants = response.data.data.variants;
                            if (_that.variants.count === 1) {
                                _that.searchForProduct = true;
                                _that.variantId = _that.variants.result;
                            }
                        }
                        _that.loadingAttributes = false;
                    })
                    .catch(function (error) {
                        console.log(error);
                        _that.loadingAttributes = false;
                    });
            },
            updateAttributeList: function(item) {
                if (item.value !== '') {
                    this.selectedAttributes.push(item);
                }
                this.getFilteredAttributes();
            },
            removeLastAttributes: function(index) {
                this.attributes = this.attributes.slice(0, index + 1);
                this.selectedAttributes = this.selectedAttributes.slice(0, index + 1);
            },
            resetErrors: function () {
                this.errors = {};
            },
            hasError: function (index) {
                return index in this.errors;
            },
            firstError: function (index) {
                return this.errors[index][0];
            },
            removeAttributeByIndex: function (index) {
                let temp = this.selectedAttributes.findIndex((item) => {
                    return item.id === index;
                });
                if (temp === -1) {
                    return;
                }
                this.selectedAttributes.splice(temp, 1);
            }
        }
    }
</script>
