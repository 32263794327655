<template>
    <div>
        <hr>
        <table v-if="variants.length > 0" class="table table-sm article-result">
            <thead class="thead-dark">
            <tr>
                <th scope="col">{{$trans('article.create.headers.productId')}}</th>
                <th scope="col">{{$trans('article.create.headers.quantityId')}}</th>
                <th scope="col">{{$trans('article.create.headers.quantity')}}</th>
                <th scope="col">{{$trans('article.create.headers.price')}}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(productVariant, index) in variants">
                <td>{{ variantId }}</td>
                <td>{{ productVariant.id }}</td>
                <td>{{ productVariant.quantity }}</td>
                <td>{{ getStandardPrice(productVariant.shippings) }}</td>
            </tr>
            </tbody>
        </table>
        <p v-if="hasError('variants')" class="invalid-feedback">{{ firstError('variants') }}</p>
        <button v-if="variants.length > 0" class="btn btn-sm btn-app-color pl-3 pr-3 mt-3 mb-3 article-save"
                @click="saveAction()">
            {{$trans('article.edit.buttons.save')}}
        </button>
        <i class="fa fa-spinner fa-spin" v-bind:class="{'collapse': !loading }"></i>
    </div>
</template>

<script>
    export default {
        props: ['variantId', 'subgroupId', 'searchForProduct'],
        data() {
            return {
                attributes: [],
                variants: [],
                categories: [],
                subgroupName: '',
                subgroupImage: '',
                productDataSheet: '',
                loading: false,
                errors: [],
            }
        },
        computed: {},
        mounted() {
          window.bus.$on('subgroupSelect', (subgroup) => {
            this.categories = subgroup.categories;
            this.subgroupName = subgroup.name;
            this.subgroupImage = subgroup.image;
          })
        },
        watch: {
            searchForProduct: function (newVal, oldVal) {
                this.getVariants();
            }
        },
        methods: {
            getStandardPrice: function (shippings) {
                let searchIndex = shippings.findIndex(shipping => shipping.type === 'Standard');
                return searchIndex > -1 ? shippings[searchIndex].price : null;
            },
            getVariants: function () {
                if (this.searchForProduct === false) return;
                let _that = this;
                _that.loading = true;
                axios.post(route('api.articles.builder.product.fetch'), {
                    'variantId': this.variantId,
                })
                    .then(function (response) {
                        _that.attributes = response.data.data.attributes;
                        _that.variants = response.data.data.quantities;
                        _that.productDataSheet = response.data.data.data_sheet;
                        _that.loading = false;
                    })
                    .catch(function (error) {
                        console.log(error);
                        _that.$set(_that.errors, 'variants', [error.response.data.message]);
                        _that.loading = false;
                    });
            },
            saveAction: function () {
                let _that = this;
                _that.loading = true;
                axios.post(route('api.articles.builder.product.store'), {
                    'id': this.variantId,
                    'variants': this.variants,
                    'attributes': this.attributes,
                    'categories': this.categories,
                    'subgroupId': this.subgroupId,
                    'subgroupName': this.subgroupName,
                    'subgroupImage': this.subgroupImage,
                    'productDataSheet': this.productDataSheet,
                })
                    .then(function (response) {
                        window.location.href = route('articles.edit', {'id': response.data.id}).url();
                        _that.loading = false;
                    })
                    .catch(function (error) {
                        console.log(error);
                        _that.$set(_that.errors, 'variants', [error.response.data.message]);
                        _that.loading = false;
                    });
            },
            resetErrors: function () {
                this.errors = {};
            },
            hasError: function (index) {
                return index in this.errors;
            },
            firstError: function (index) {
                return this.errors[index][0];
            },
        }
    }
</script>
