<template>
    <div>
        <div class="form-group row">
            <label class="col-sm-2 offset-sm-2 col-form-label">{{ attribute.name }}</label>
            <div class="col-sm-6">
                <select v-model="attributeOptionSelected" @change="attributeWasSelected(index, attribute.id)"
                        v-bind:disabled="loadingAttributes" class="form-control form-control-sm"
                        v-bind:name="'attribute[' + attribute.id + ']'">
                    <option value="">{{$trans('article.create.selectOptions.selectAttribute')}}</option>
                    <option v-for="(valueName, valueId) in attribute.values" v-bind:value="valueId"
                            v-bind:disabled="attributeIsDisabled(valueId)">
                        {{ valueName }}
                    </option>
                </select>
            </div>
            <div v-show="loadingAttributes" class="col-sm-2">
                <i class="fa fa-spinner fa-spin" v-bind:class="{'collapse': !loadingAttributes }"></i>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['index', 'attribute', 'trimmedAttribute', 'loadingAttributes'],
        data() {
            return {
                attributeOptionSelected: '',
            }
        },
        methods: {
            attributeWasSelected: function () {
                window.bus.$emit('removeAttributeByIndex', this.index);
                window.bus.$emit('updateAttributeList', {id: this.index, value: this.attributeOptionSelected});
            },
            attributeIsDisabled: function (valueId) {
                if (this.trimmedAttribute === undefined) return false;
                return this.trimmedAttribute.values[valueId] === undefined;
            }
        }
    }
</script>