<template>
    <div>
        <div class="card">
            <div class="card-header">
                <h5 class="mb-0">
                    <button type="button" class="btn btn-link" data-toggle="collapse" data-target="#collapseTwo"
                            aria-expanded="true" aria-controls="collapseTwo">
                        {{$trans('article.edit.headers.variants')}}
                        <i class="fa fa-chevron-down"></i>
                    </button>
                </h5>
            </div>
            <div id="collapseTwo" class="collapse" data-parent=".accordion">
                <div class="card-body">
                    <table class="table table-sm variants">
                        <thead class="thead-dark">
                        <tr>
                            <th scope="col">{{$trans('article.edit.headers.id')}}</th>
                            <th scope="col">{{$trans('article.edit.headers.quantity')}}</th>
                            <th scope="col">{{$trans('article.edit.headers.price')}}</th>
                            <th scope="col">
                                <button class="btn btn-sm btn-outline-success btn-toggle" v-show="!allQuantitiesEnabled"
                                        v-on:click.stop.prevent="toggleAllStatuses()">
                                    {{$trans('article.edit.labels.btnEnableAll')}}
                                </button>
                                <button class="btn btn-sm btn-outline-danger btn-toggle" v-show="allQuantitiesEnabled"
                                        v-on:click.stop.prevent="toggleAllStatuses()">
                                    {{$trans('article.edit.labels.btnDisableAll')}}
                                </button>
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) in variants">
                            <td v-bind:class="stateClass(item.status)">
                                {{item.quantity_id}}
                            </td>
                            <td v-bind:class="stateClass(item.status)">{{item.quantity}}</td>
                            <td v-bind:class="stateClass(item.status)">{{item.price}}</td>
                            <td>
                                <button class="btn btn-sm btn-outline-success btn-toggle" v-show="item.status == 0"
                                        v-on:click.stop.prevent="toggleStatus(index, item.id)">
                                    {{$trans('article.edit.labels.btnEnable')}}
                                </button>
                                <button class="btn btn-sm btn-outline-danger btn-toggle" v-show="item.status == 1"
                                        v-on:click.stop.prevent="toggleStatus(index, item.id)">
                                    {{$trans('article.edit.labels.btnDisable')}}
                                </button>
                                <div class="text-center mw-100" v-if="item.status == 3">{{$trans('article.index.statuses.3')}}</div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ["articleId"],
        data() {
            return {
                variants: [],
                loading: false,
                quantityId: '',
                quantity: '',
                price: '',
                allQuantitiesEnabled: true
            }
        },
        mounted() {
            this.loading = true;
            let _that = this;
            axios.get(route('api.article.show', {'id': this.articleId}))
                .then(response => {
                    this.variants = response.data;

                    this.variants.forEach(function (item) {
                        if (item.status == 0) _that.allQuantitiesEnabled = false;
                        return;
                    });

                    this.loading = false;
                })
                .catch(function (error) {
                    console.log(error);
                    this.loading = false;
                });
        },
        methods: {
            reset() {
                this.quantityId = '';
                this.quantity = '';
                this.price = '';
            },
            toggleStatus(index, id) {
                axios.patch(route('api.article.toggle', {id: id, type: 'single'}))
                    .then(response => {
                        this.variants[index].status = response.data;
                    })
                    .catch(error => {
                        console.log(error);
                    });
            },
            stateClass: function (status) {
                if (status === 0) return 'table-danger';
                if (status === 1) return 'table-success';
                if (status === 3) return 'table-warning';
                return '';
            },
            toggleAllStatuses: function () {
                let _that = this;
                axios.patch(route('api.article.toggle', {id:this.articleId, type:'all' ,action: Number(!this.allQuantitiesEnabled)}))
                    .then(response => {
                        this.variants = response.data;
                        this.allQuantitiesEnabled = !this.allQuantitiesEnabled;
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
        }
    }
</script>
