<template>
    <div>
        <div class="container article-list">
            <div v-show="initialLoaded" id="productGroupDescription" class="row">
                <div class="col-md py-5 px-2">
                    <h4 class="" itemprop="name">{{categoryHeader}}</h4>
                    <div class="productOptionsGroup">
                        <h5 v-if="Object.keys(filters).length > 0">{{$trans('article.create.labels.features')}}</h5>
                        <div v-for="(attributeGroup, key)  in filters" :id=key class="productOptions">
                            <h5>{{attributeGroup.name}}:</h5>
                            <select @change="addFilter($event, key)" class="form-control form-control-sm"
                                    name="variants">
                                <option value=-1>{{$trans('article.create.selectOptions.selectAttribute')}}</option>
                                <option v-for="attribute in Object.values(attributeGroup.values).sort((a,b) => a - b)"
                                        :value="getKeyByValue(attribute, attributeGroup.values)"
                                        :selected="isAttributeSelected(getKeyByValue(attribute, attributeGroup.values))"
                                >{{attribute}}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="col-md-8 py-4">
                    <div v-for="article in articles" class="row">
                        <div id="productGroupDescriptionImg" class="col-sm-4">
                            <img class="productGroupImage"
                                 :src="article.artwork_image ?
                             '/storage/articles/' + article.id + '/' + article.artwork_image.file
                             : '/img/image_missing.jpg'"
                                 :alt="article.title">
                        </div>
                        <div id="productGroupDescriptionText" itemprop="description" class="col-sm-8">
                            <h5 class="productName" itemprop="name">
                                <a :href="articleVariantRoute(article.id)">
                                    {{article.title}}
                                </a>
                            </h5>
                            <div class="pl-3">{{article.description}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="overlay" v-show="loading">
                <square-grid></square-grid>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ['subgroupId'],
        data() {
            return {
                selectedFilters: {},
                articles: [],
                filters: [],
                category: '',
                subgroup: '',
                thirdLevelCategory: '',
                loading: false,
                initialLoaded: false
            }
        },
        mounted() {
            this.filter();
        },
        computed: {
          categoryHeader: function () {
            let categoryHeader = this.category + ' > ' + this.subgroup;
            if (this.thirdLevelCategory !== '') {
              categoryHeader += ' > ' + this.thirdLevelCategory;
            }
            return categoryHeader;
          },
        },
        methods: {
            isAttributeSelected(attributeId) {
                return Object.values(this.selectedFilters).indexOf(attributeId) > -1;
            },
            getKeyByValue(attribute, values) {
                for (const [key, value] of Object.entries(values)) {
                    if (value === attribute) {
                       return key;
                    }
                }
            },
            articleVariantRoute(articleId) {
                return route('shop.article_variants', articleId);
            },
            addFilter(event, attributeGroupKey) {
                let selectedValue = event.target.value;
                if (selectedValue === "-1") {
                    if (this.selectedFilters.hasOwnProperty(attributeGroupKey)) {
                        delete this.selectedFilters[attributeGroupKey];
                    }
                } else {
                    this.selectedFilters[attributeGroupKey] = selectedValue;
                }
                this.filter();
            },
            filter() {
                let _that = this;
                _that.loading = true;
                axios.post(route('api.shop.filter'), {'id': this.subgroupId, 'selectedFilters': this.selectedFilters})
                    .then(function (response) {
                        _that.category = response.data.data.category;
                        _that.subgroup = response.data.data.subgroup;
                        _that.thirdLevelCategory = response.data.data.thirdLevelCategory;
                        _that.articles = response.data.data.articles;
                            _that.filters = response.data.data.filters;

                        _that.loading = false;
                        _that.initialLoaded = true;
                    })
                    .catch(function (error) {
                        console.log(error);
                        _that.loading = false;
                    });
            }
        }
    }
</script>
