import "core-js/stable";
import "regenerator-runtime/runtime";


/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');
const VueLang = require('@eli5/vue-lang-js');

import translations from './lang.json';

Vue.use(VueLang, {
    messages: translations,
    locale: window.Laravel.locale,
    fallback: 'en'
});
const files = require.context('./', true, /\.vue$/i);

files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

window.bus = new Vue({});

const app = new Vue({
    el: '.app'
});

Document.prototype.ready = function (callback) {
    if (callback && typeof callback === 'function') {
        document.addEventListener("DOMContentLoaded", function () {
            if (document.readyState === "interactive" || document.readyState === "complete") {
                return callback();
            }
        });
    }
};

require('./menu.js');
require('./flatpicker');
require('./choices');
