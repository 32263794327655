<template>
  <div>
    <div v-if="alreadyUploaded" class="form-group row">
      <div class="col-sm-5 offset-sm-2">
        <div>{{ $trans('upload.create.messages.fileAlreadyUploaded') }}</div>
        <button v-if="canUploadAnotherFile()" class="btn btn-sm btn-app-color pl-3 pr-3 mt-3 mb-3 article-save"
                @click="uploadAnother()">
          {{ $trans('upload.create.buttons.uploadAnotherFile') }}
        </button>
      </div>
    </div>
    <div v-if="uploadAllowedFormat.hasOwnProperty('status') && uploadAllowedFormat.status === false" class="alert alert-warning">
      <div>{{ $trans('upload.create.messages.uploadNotAllowed') }}</div>
    </div>
    <div v-if="!alreadyUploaded && uploadAllowedFormat.hasOwnProperty('status') && uploadAllowedFormat.status === true">
      <div class="form-group row">
        <div class="col-sm-8 offset-sm-2">
          <ul class="list-group">
            <li v-for="(item, index) in files" class="list-group-item">
              <span class="badge badge-pill badge-dark">{{ index + 1 }}</span> <a target="_blank"
                                                                                  v-bind:href="item.url">{{
                item.name
              }}</a>
              <span class="float-right">
                            <i class="fa fa-spinner fa-spin" v-show="item.loading"></i>
                            <i class="fa fa-trash" v-show="!item.loading" v-on:click="remove(index)"></i>
                        </span>
            </li>
          </ul>
          <div v-if="hasError()" class="alert alert-warning mb-0" role="alert">{{ firstError() }}</div>
        </div>
      </div>
      <div class="form-group row" v-show="!stackFull">
        <label class="col-sm-3 col-form-label">{{ $trans('upload.create.labels.newUpload') }}</label>
        <div class="col-sm-7">
                <span v-show="loading">
                    <i class="fa fa-spinner fa-spin"></i>
                </span>
          <div class="custom-file" v-show="!loading">
            <input type="file" class="custom-file-input" name="files" ref="filesInput" multiple
                   v-on:change="handleFilesSelected()" accept="application/pdf, application/zip"/>
            <label class="custom-file-label"
                   v-on:click="addFiles()">{{ $trans('upload.create.placeholders.chooseFile') }}</label>
          </div>
          <div class="form-check" v-show="!loading">
            <input type="checkbox" class="form-check-input" id="checkPrintData" name="checkPrintData"
                   v-model="checkPrintData">
            <label class="form-check-label"
                   for="checkPrintData">{{ $trans('upload.create.placeholders.checkPrintData') }}</label>
          </div>
          <div v-if="hasError()" class="alert alert-warning mb-0" role="alert">{{ firstError() }}</div>
        </div>
      </div>
      <div v-if="canUpload === false && uploaded === false && message !== ''" v-bind:class="printDataCheckStatusClass"
           role="alert">{{ message }}
      </div>
      <div v-if="(uploaded || canUpload) && message !== ''" class="alert alert-success mb-0" role="alert">{{
          message
        }}
      </div>
      <div v-if="canUpload" class="col-sm-6 offset-sm-4">
        <button v-on:click="upload()" type="submit" class="btn btn-sm btn-app-color pl-4 pr-4">
          {{ $trans('upload.create.buttons.upload') }}
        </button>
      </div>
      <div v-if="uploadAllowedFormat.hasOwnProperty('singleUploadMessage') && !stackFull" class="alert alert-info mt-2">
        {{uploadAllowedFormat.singleUploadMessage}}
      </div>
      <div v-if="uploadAllowedFormat.hasOwnProperty('multiUploadMessage') && !stackFull" class="alert alert-info mt-2">
        {{uploadAllowedFormat.multiUploadMessage}}
      </div>
      <input type="hidden" v-for="(file, key) in files" v-bind:name="'pdf[' + key +']'" v-bind:value="file.id"/>
    </div>
    <div class="overlay" v-show="loading">
      <square-grid></square-grid>
    </div>
  </div>
</template>

<script>
export default {
  props: ["orderItem", "fileUrl", "uploadAllowedFormat"],
  data() {
    return {
      errors: [],
      files: [],
      temps: [],
      maxFiles: 1,
      loading: false,
      uploaded: false,
      canUpload: false,
      checkPrintData: false,
      orderItemId: '',
      message: '',
      triggeredPrintDataStatus: false,
      printDataCheckStatusClass: 'alert alert-warning',
      alreadyUploaded: false
    }
  },
  computed: {
    stackFull: function () {
      return this.files.length >= this.maxFiles;
    },
  },
  mounted() {
    this.orderItemId = this.orderItem.id;
    if (this.orderItem.uploaded_file_location !== null) {
      this.alreadyUploaded = true;
    }
  },
  methods: {
    addFiles() {
      this.$refs.filesInput.click();
    },
    handleFilesSelected() {
      this.resetErrors();
      let uploadedFiles = this.$refs.filesInput.files;
      for (let i = 0; i < uploadedFiles.length; i++) {
        if (this.maxFiles <= this.temps.length + this.files.length) {
          this.errors.push("Too many files selected.");
          continue;
        }
        this.temps.push(uploadedFiles[i]);
      }
      this.submitFiles();
    },
    submitFiles() {
      let formData = new FormData();
      for (let i = 0; i < this.temps.length; i++) {
        let file = this.temps[i];
        formData.append('files[' + i + ']', file);
      }
      formData.append('checkPrintData', this.checkPrintData);
      formData.append('orderItemId', this.orderItemId);
      let _that = this;
      this.$refs.filesInput.value = "";
      this.loading = true;
      axios.post(
          route('uploads.store'),
          formData,
          {headers: {'Content-Type': 'multipart/form-data'}}
      )
          .then(function (response) {
            _that.temps = [];
            _that.loading = false;
            _that.message = response.data.message;
            _that.files = response.data.files;
            _that.canUpload = response.data.canUpload;
            _that.triggeredPrintDataStatus = response.data.triggeredPrintDataStatus;
            if (_that.triggeredPrintDataStatus === true) {
              setTimeout(() => {  _that.checkPrintDataStatus(); }, 20000);
            }
          })
          .catch(function (error) {
            console.log(error);
            _that.loading = false;
            _that.errors[0] = [error.response.data.message];
            _that.temps = [];
          });
    },
    upload() {
      let formData = new FormData();
      for (let i = 0; i < this.files.length; i++) {
        formData.append('files[' + i + ']', this.files[i].path);
      }
      formData.append('orderItemId', this.orderItemId);
      let _that = this;
      this.$refs.filesInput.value = "";
      this.loading = true;
      axios.post(
          route('uploads.send'),
          formData,
          {headers: {'Content-Type': 'multipart/form-data'}}
      )
          .then(function (response) {
            _that.temps = [];
            _that.loading = false;
            _that.uploaded = true;
            _that.message = response.data.message;
            _that.canUpload = response.data.canUpload;
          })
          .catch(function (error) {
            console.log(error);
            _that.loading = false;
            _that.errors[0] = [error.response.data.message];
            _that.temps = [];
          });
    },
    remove(index) {
      this.resetErrors();
      this.message = '';
      this.files.splice(index, 1);
      this.canUpload = false;
      this.uploaded = false;
    },
    checkPrintDataStatus() {
      let formData = new FormData();
      for (let i = 0; i < this.files.length; i++) {
        formData.append('files[' + i + ']', this.files[i].path);
      }
      formData.append('orderItemId', this.orderItemId);
      let _that = this;

      axios.post(route('uploads.check-print-data'), formData,
          {headers: {'Content-Type': 'multipart/form-data'}})
          .then(function (response) {
            _that.message = response.data.message;
            if (response.data.status === 0) {
              setTimeout(() => {  _that.checkPrintDataStatus(); }, 5000);
            } else if (response.data.status === 1) {
              _that.printDataCheckStatusClass = 'alert alert-success';
              _that.canUpload = true;
            } else {
              _that.printDataCheckStatusClass = 'alert alert-danger';
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    uploadAnother() {
      this.alreadyUploaded = false;
    },
    canUploadAnotherFile() {
      return this.orderItem.api_status === 21 || this.orderItem.api_status === 22;
    },
    resetErrors: function () {
      this.errors = [];
      this.printDataCheckStatusClass = 'alert alert-warning';
    },
    hasError: function () {
      return this.errors.length;
    },
    firstError: function () {
      return this.errors[0];
    },
  }
}
</script>
