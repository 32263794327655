<template>
    <div>
        <div class="row" v-show="!loading && !hasCatalogArticles">
            <div class="col-sm-3 mt-2 mb-3">
                {{$trans('catalog.article.edit.labels.addFromSubgroup')}}
            </div>
            <div class="col-sm-5">
                <product-autosuggest-input :options="subgroups"></product-autosuggest-input>
            </div>
            <div class="col-sm-4">
                <button class="btn btn-sm btn-app-color pl-3 pr-3 mt-2 mb-3"
                        @click="addSubgroup"
                        :disabled="subgroupSelected === ''">
                    {{$trans('catalog.article.edit.buttons.addFromSubgroup')}}
                </button>
            </div>
            <div class="col-sm-10 mt-2 mb-3">
            <p>{{$trans('catalog.article.edit.table.subgroupAddInfo')}}</p>
            </div>
        </div>
        <div role='alert' v-if="responseMessage.type === 'danger'" class="alert alert-danger" >{{ responseMessage.message }}</div>
        <div role='alert' v-if="responseMessage.type === 'success'" class="alert alert-success">{{ responseMessage.message }}</div>
        <div class="row">
            <div class="col-sm-10">
                <div class="article-search">
                    <div>
                        <input type="text" placeholder="Search in existing articles" v-model="search" @input="debounceSearch" class="form-control form-control-sm mb-2 mr-sm-2">
                    </div>
                    <div class="article-list mb-2" v-show="this.allArticles.length > 0 && !loading">
                        <ul class="list-group">
                            <li v-for="article in allArticlesFiltered" class="list-group-item">
                                <a href="#" v-on:click.stop.prevent v-on:click="onSelect(article.id)">{{ article.title }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-2">
                <button v-if="this.buttonAddAll" v-on:click="addAllItems()" type="button" class="btn btn-sm btn-outline-danger">{{$trans('catalog.article.edit.buttons.all')}}</button>
            </div>
        </div>
      <hr>

        <table v-show="!loading" class="table table-sm" id="sortable">
            <thead class="thead-dark">
            <tr>
                <th scope="col" data-sort-method="thead">{{$trans('catalog.index.headers.id')}}</th>
                <th scope="col">{{$trans('catalog.article.edit.headers.name')}}</th>
                <th scope="col">{{$trans('catalog.article.edit.headers.status')}}</th>
                <th scope="col">{{$trans('catalog.article.edit.headers.catalogStatus')}}</th>
                <th scope="col">{{$trans('catalog.article.edit.headers.artwork')}}</th>
                <th scope="col">{{$trans('catalog.article.edit.headers.flyeralarmProduct')}}</th>
                <th scope="col"></th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td colspan="7">
              <div class="row" v-show="!loading && hasCatalogArticles">
                <div class="col-sm-3 mt-2 mb-3">
                  {{$trans('catalog.article.edit.labels.removeByKeyword')}}
                </div>
                <div class="col-sm-6">
                  <input type="text" v-model="searchKeyword" class="form-control form-control-sm mb-2 mr-sm-2">
                </div>
                <div class="col-sm-3">
                  <button class="btn btn-sm btn-app-color pl-3 pr-3 mt-2 mb-3"
                          @click="removeByKeyword">
                    {{$trans('catalog.article.edit.buttons.remove')}}
                  </button>
                </div>
              </div>
            </td>
            <tr>
            <tr v-bind:class="{'table-warning' : catalogArticle.articlePivot.state === 'delete'}" v-for="(catalogArticle, index) in publishedArticles">
                <td>{{ catalogArticle.id }}</td>
                <td>{{ catalogArticle.title }}</td>
                <td><span class="badge badge-info">{{ catalogArticle.status }}</span></td>
                <td><span class="badge badge-info">{{ catalogArticle.articlePivot.state }}</span></td>
                <td><a v-if="catalogArticle.artwork" v-bind:href="catalogArticle.artwork.url" target="_blank">{{catalogArticle.artwork.name}}</a>
                </td>
                <td>{{ catalogArticle.product_id }}</td>
                <td>
                    <button class="btn btn-sm btn-outline-info" @click="editAction(publishedArticles, index)">{{$trans('catalog.article.edit.buttons.edit')}}</button>
                    <button v-if="catalogArticle.articlePivot.state !== 'delete'" class="btn btn-sm btn-outline-danger" @click="removeAction(catalogArticle.id)">{{$trans('catalog.article.edit.buttons.remove')}}</button>
                </td>
            </tr>
            <tr v-show="disabledArticles.length > 0"><td colspan="5">{{$trans('catalog.article.edit.table.notify')}}</td></tr>
            <tr class="table-warning" v-for="(catalogArticle, index) in disabledArticles">
                <td>{{ catalogArticle.title }}</td>
                <td><span class="badge badge-info">{{ catalogArticle.status }}</span></td>
                <td><a v-if="catalogArticle.artwork" v-bind:href="catalogArticle.artwork.url" target="_blank">{{catalogArticle.artwork.name}}</a>
                </td>
                <td>{{ catalogArticle.product_id }}</td>
                <td>
                    <button class="btn btn-sm btn-outline-info" @click="editAction(disabledArticles, index)">{{$trans('catalog.article.edit.buttons.edit')}}</button>
                    <button class="btn btn-sm btn-outline-danger" @click="removeAction(catalogArticle.id)">{{$trans('catalog.article.edit.buttons.remove')}}</button>
                </td>
            </tr>
            </tbody>
        </table>
        <button v-show="!loading" class="btn btn-sm btn-app-color pl-3 pr-3 mt-3 mb-3"
                @click="saveAction()">{{$trans('catalog.article.edit.buttons.saveAndPublish')}}
        </button>
      <div class="overlay" v-show="loading">
        <square-grid></square-grid>
      </div>
    </div>
</template>



<script>
    export default {
        props: [
            'catalogId',
            'buttonAddAll'
        ],
        data() {
            return {
                catalogArticles: [],
                allArticles: [],
                selected: '',
                search: '',
                searchKeyword: '',
                responseMessage: {
                    message: '',
                    type: ''
                },
                loading: true,
                subgroups: [],
                subgroupSelected: '',
                hasCatalogArticles: false
            }
        },
        computed: {
            allArticlesFiltered: function () {
                let _that = this;
                let notSelected = this.allArticles.filter(function (article) {
                    return !_that.catalogArticles.find(function (catalogArticle) {
                        return article.id === catalogArticle.id;
                    });
                });
                return notSelected.filter(function (article) {
                    let string = _that.search.replace(/([()[{*+.$^\\|?])/g, '\\$1');
                    return article.title.toLowerCase().search(string.toLowerCase()) !== -1;
                });
            },
            publishedArticles: function() {
                return this.catalogArticles.filter(function (article) {
                    return article.status_id === 1;
                });
            },
            disabledArticles: function() {
                return this.catalogArticles.filter(function (article) {
                    return article.status_id !== 1;
                });
            }
        },
        watch: {
          allArticles: function () {
            let _that = this;
            _that.allArticlesFiltered = this.allArticles.filter(function (article) {
              return !_that.catalogArticles.find(function (catalogArticle) {
                return article.id === catalogArticle.id;
              });
            });
          },
        },
        mounted() {
            let _that = this;
            this.loading = true;
            axios.get(route('api.catalogs.articles.index', this.catalogId), {})
                .then(function (response) {
                    _that.catalogArticles = response.data.data;
                    _that.hasCatalogArticles = _that.catalogArticles.length > 0;
                  _that.loading = false;
                })
                .catch(function (error) {
                  _that.loading = false;
                    console.log(error);
                });
            this.getSubGroups();
            window.bus.$on('subgroupSelect', (subgroup) => {
                this.subgroupSelected = subgroup.id;
            })
        },
        methods: {
          debounceSearch:_.debounce(function (e) {
            this.searchByKeyword();
          }, 500),
            onSelect(id) {
                this.selected = id;
                this.articleSelected();
            },
            editAction: function (data, index) {
                let currentArticle = data[index];
                if(currentArticle.type === 'standard') {
                    window.location.href = route('articles.edit', currentArticle.id);
                } else {
                    window.location.href = route('individual-articles.edit', currentArticle.id);
                }
            },
            removeAction: function (articleId) {
                let index = this.catalogArticles.findIndex((item) => item.id === articleId);
                if(index !== -1) this.catalogArticles.splice(index, 1);
            },
            articleSelected: function () {
                if (this.selected === '') {
                    return;
                }
                let article = this.allArticles.find(article => article.id === this.selected);
                article['articlePivot'] = {state: 'new'};
                this.catalogArticles.push(article);
                this.selected = '';
            },
            addAllItems: function () {
              let _that = this;
              if (this.allArticlesFiltered.length > 0) {
                _that.hasCatalogArticles = true;
                this.allArticlesFiltered.forEach(function(article) {
                  article['articlePivot'] = {state: 'new'};
                  _that.catalogArticles.push(article);
                });
              }
            },
            removeByKeyword: function () {
              this.loading = true;
              let _that = this;
              let indexes = [];
              let valObj = this.catalogArticles.filter(function(elem, index){
                if(elem.title.indexOf(_that.searchKeyword) !== -1) indexes.push(index);
              });
              if (indexes.length > 0) {
                indexes.forEach(function(index) {
                  if(index !== -1) _that.catalogArticles.splice(index, 1);
                });
              }
              this.loading = false;
            },
          searchByKeyword: function () {
            this.loading = true;
            let _that = this;
            axios.get(route('api.articles.index', {'search': this.search}))
                .then(function (response) {
                  _that.allArticles = response.data.data;
                  _that.loading = false;
                })
                .catch(function (error) {
                 _that.loading = false;
                  console.log(error);
                });
          },
            saveAction: function () {
                this.loading = true;
                axios.post(route('api.catalogs.articles.store', this.catalogId),
                    {articleIds: this.catalogArticles.map(article => article.id)}
                ).then((response) => {
                    window.location.reload();
                    this.responseMessage = response.data;
                })
                .catch((error) => {
                    this.loading = false;
                    this.responseMessage = response.data;
                    console.log(error);
                });
            },
            getSubGroups: function () {
                let _that = this;
                _that.loadingSubgroups = true;
                axios.get(route('api.articles.builder.subgroups'), {})
                    .then(function (response) {
                        _that.subgroups = response.data.data;
                        _that.loadingSubgroups = false;
                    })
                    .catch(function (error) {
                        console.log(error);
                        _that.$set(_that.errors, 'subgroups', [error.response.data.message]);
                        _that.loadingSubgroups = false;
                    });
            },
            addSubgroup: function () {
                this.loading = true;
                let _that = this;
                _that.loadingSubgroups = true;
                let formData = new FormData();
                formData.append('subgroupId', this.subgroupSelected);
                formData.append('catalogId', this.catalogId);
                axios.post(route('api.catalogs.articles.addsubgroup'), formData)
                    .then(function (response) {
                        window.location.href = route('catalogs.index');
                    })
                    .catch(function (error) {
                        console.log(error);
                        _that.$set(_that.errors, 'subgroups', [error.response.data.message]);
                        _that.loadingSubgroups = false;
                    });
            }
        }
    }
</script>
