<template>
  <div>
    <div class="container article-list">
      <div id="productGroupDescription" class="row">
        <div class="col-md-12 py-4">
          <div class="row">
            <div id="productGroupDescriptionImg" class="col-sm-4">
              <img class="productGroupImage"
                   :src="article.artwork_image ?
                             '/storage/articles/' + article.id + '/' + article.artwork_image.file
                             : '/img/image_missing.jpg'"
                   :alt="article.title">
            </div>
            <div id="productGroupDescriptionText" itemprop="description" class="col-sm-8">
              <h5 class="productName" itemprop="name">
                <!--                                <a :href="route('shop.show', item.quantity_id)">-->
                <!--                                    {{item.title}} ({{item.quantity}} {{item.measure_unit}})-->
                <!--                                </a>-->
                {{ article.title }}
              </h5>
              <div class="pl-3">
                {{ article.description }}
                <br>
                <div class="pt-3">
                  <h6>{{ $trans('article.create.labels.features') }}</h6>
                  <div v-for="item in attributes.data">
                    <div>{{ item.attribute_name }}: {{item.attribute_value}}</div>
                  </div>
                </div>
                <br>
                <!--                                <h5>{{ item.price}} {{ config('flyeralarm.base.currency') }}</h5>-->
                <select @change="redirectToProduct" class="form-control form-control-sm" name="variants">
                  <option value="">{{ $trans('article.create.selectOptions.selectQuantity') }}</option>
                  <option v-for="item in article.article_variants" v-bind:value="item.quantity_id">
                    {{ item.quantity }} Stück - {{ Intl.NumberFormat('de-DE', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  }).format(item.price) }} {{ currency }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['article', 'attributes', 'currency'],
  data() {
    return {}
  },
  mounted() {

  },
  methods: {
    redirectToProduct(event) {
      window.location.href = route('shop.show', {quantityid: event.target.value, articleid: this.article.id});
    },
    submit() {
      let _that = this;
      axios.post(route('cart.submit', {}), {})
          .then(function (response) {
            location.reload();
            _that.loading = false;
          })
          .catch(function (error) {
            console.log(error);
            location.reload();
            _that.loading = false;
          });
    }
  }
}
</script>