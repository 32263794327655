<template>
    <div>
        <div class="form-group row">
            <div class="col-sm-8 offset-sm-2">
                <ul class="list-group">
                    <li v-for="(item, index) in files" class="list-group-item">
                        <span class="badge badge-pill badge-dark">{{ index + 1 }}</span> <a v-bind:href="item.url">{{item.name}}</a>
                        <span class="badge badge-pill badge-primary">{{$trans('article.edit.labels.types.pdf')}}</span>
                        <span class="float-right">
                            <i class="fa fa-spinner fa-spin" v-show="item.loading"></i>
                            <i class="fa fa-trash" v-show="!item.loading" v-on:click="remove(index, item.id)"></i>
                        </span>
                    </li>
                </ul>
                <div v-if="hasError()" class="alert alert-warning mb-0" role="alert">{{ firstError() }}</div>
            </div>
        </div>
        <div class="form-group row" v-show="!stackFull">
            <label class="col-sm-2 offset-sm-2 col-form-label">{{$trans('article.edit.labels.pdfUpload')}}</label>
            <div class="col-sm-6">
                <span v-show="loading">
                    <i class="fa fa-spinner fa-spin"></i>
                </span>
                <div class="custom-file" v-show="!loading">
                    <input type="file" class="custom-file-input" name="files" ref="filesInput" multiple
                           v-on:change="handleFilesSelected()" accept="application/pdf, application/zip"/>
                    <label class="custom-file-label" v-on:click="addFiles()">{{$trans('article.edit.placeholders.chooseFile')}}</label>
                </div>
                <div class="form-check" v-show="!loading">
                    <input type="checkbox" class="form-check-input" id="pdfPreview" name="pdfPreview" v-model="pdfPreview">
                    <label class="form-check-label" for="pdfPreview">{{$trans('article.edit.placeholders.pdfPreview')}}</label>
                </div>
            </div>
        </div>
        <input type="hidden" v-for="(file, key) in files" v-bind:name="'pdf[' + key +']'" v-bind:value="file.id"/>
    </div>
</template>

<script>
    export default {
        props: ["articleId", "validationError"],
        data() {
            return {
                errors: [],
                files: [],
                temps: [],
                maxFiles: 1,
                loading: false,
                type: 1,
                pdfPreview: true
            }
        },
        computed: {
            stackFull: function () {
                return this.files.length >= this.maxFiles;
            },
        },
        mounted() {
            let _that = this;
            _that.errors = _that.validationError ? _that.validationError : [];
            this.loading = true;
            axios.get(route('api.articles.artworks.type', {'id': this.articleId, 'type': this.type}))
                .then(function (response) {
                    _that.files = response.data.data;
                    _that.loading = false;
                })
                .catch(function (error) {
                    console.log(error);
                    _that.loading = false;
                    _that.errors[0] = [error.response.data.message];
                });
        },
        methods: {
            addFiles() {
                this.$refs.filesInput.click();
            },
            handleFilesSelected() {
                this.resetErrors();
                let uploadedFiles = this.$refs.filesInput.files;
                for (let i = 0; i < uploadedFiles.length; i++) {
                    if (this.maxFiles <= this.temps.length + this.files.length) {
                        this.errors.push("Too many pdfs selected.");
                        continue;
                    }
                    this.temps.push(uploadedFiles[i]);
                }
                this.submitFiles();
            },
            remove(index, id) {
                this.resetErrors();
                this.files[index].loading = true;
                let _that = this;
                axios.delete(route('api.articles.artworks.destroy', {'article': this.articleId, 'artwork': id}))
                    .then(function (response) {
                        _that.files.splice(index, 1);
                    })
                    .catch(function (error) {
                        console.log(error);
                        _that.files[index].loading = false;
                        _that.errors[0] = [error.response.data.message];
                    });
            },
            submitFiles() {
                let formData = new FormData();
                for (let i = 0; i < this.temps.length; i++) {
                    let file = this.temps[i];
                    formData.append('pdfs[' + i + ']', file);
                }
                formData.append('type', this.type);
                formData.append('pdfPreview', this.pdfPreview);
                let _that = this;
                this.$refs.filesInput.value = "";
                this.loading = true;
                axios.post(
                    route('api.articles.artworks.store', {'article': this.articleId}),
                    formData,
                    {headers: {'Content-Type': 'multipart/form-data'}}
                )
                    .then(function (response) {
                        _that.files = response.data.data;
                        _that.temps = [];
                        _that.loading = false;
                        window.bus.$emit('refreshArticleImages');
                    })
                    .catch(function (error) {
                        console.log(error);
                        _that.loading = false;
                        _that.errors[0] = [error.response.data.message];
                        _that.temps = [];
                    });
            },
            resetErrors: function () {
                this.errors = [];
            },
            hasError: function () {
                return this.errors.length;
            },
            firstError: function () {
                return this.errors[0];
            },
        }
    }
</script>
